<template>
  <div>
    <pwd-contair>
      <div slot="menu">商品列表</div>
      <div slot="pwdContair" style="min-height: 400px">
        <div v-if="userInfo.roleType == '2'">
          <el-alert
            title="您的账号无法进行新增商品"
            type="warning"
            description="请登录或者注册供应商账号再进行操作！"
            show-icon
            :closable="false"
          >
          </el-alert>
        </div>
        <div v-else>
          <div v-if="userInfo.authentication == 'Y'">
            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="addGoods()"
              >新增</el-button
            >
            <div style="float: right">
              <el-form :inline="true" :model="queryParam">
                <el-form-item label="商品名称">
                  <el-input
                    v-model.trim="queryParam.goodsTitle"
                    placeholder="商品名称"
                    size="small"
                    clearable
                    style="width: 200px"
                    @keyup.enter.native="loadData()"
                  ></el-input>
                </el-form-item>
                <el-form-item label="商品分类">
                  <el-cascader
                    size="small"
                    :options="goodsTypeList"
                    :props="{
                      checkStrictly: true,
                      value: 'id',
                      label: 'categoryName',
                    }"
                    clearable
                    v-model="queryParam.goodsTypeId"
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="商品状态">
                  <el-select
                    v-model="queryParam.goodsStatus"
                    placeholder="商品状态"
                    size="small"
                    clearable
                    style="width: 150px"
                  >
                    <el-option
                      :label="list.dictValue"
                      :value="list.dictKey"
                      v-for="list in goodsStatusDict"
                      :key="list.dictKey"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="loadData()"
                    size="small"
                    icon="el-icon-search"
                    >查询</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div v-else>
            <el-alert
              title="您的账号暂时无法新增商品"
              type="warning"
              show-icon
              :closable="false"
            >
              <div>
                请前往信息管理进行账号认证成功后即可新增商品！
                <el-button
                  style="padding: 0"
                  type="text"
                  @click="goAuthentication()"
                  >前往认证></el-button
                >
              </div>
            </el-alert>
          </div>
        </div>
        <div class="invoiceFrom Mtp15">
          <el-table
            :header-cell-style="{ background: '#f4f4f4' }"
            :data="dataSource"
            style="width: 100%"
            v-loading="loading"
            max-height="650"
            size="mini"
          >
            <el-table-column prop="goodNo" label="商品编号"> </el-table-column>
            <el-table-column prop="goodsTitle" label="商品标题	">
            </el-table-column>
            <el-table-column prop="goodsTypeName" label="商品分类">
            </el-table-column>
            <el-table-column
              prop="goodsStatus"
              label="商品状态"
              width="100px"
              align="center"
            >
              <template slot-scope="scope">
                <div style="color: red; cursor: pointer;" v-if="scope.row.goodsStatus === '3'" @click="() => onCLickStatus(scope.row.auditReason)">
                  审核不通过<br/>查看原因
                </div>
                <div>
                  {{ mattchingDict(scope.row.goodsStatus,scope.row.auditReason) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="updateTime"
              label="更新时间"
              width="140px"
              align="center"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="action"
              label="操作"
              width="250"
            >
              <template slot-scope="scope">
                <router-link
                  tag="a"
                  :to="{ name: 'GoodDetail', query: { no: scope.row.goodNo } }"
                  target="_blank"
                >
                  <el-button type="text" size="small" icon="el-icon-zoom-in"
                    >商品查看</el-button
                  >
                </router-link>
                <el-button
                  style="margin-left: 10px"
                  icon="el-icon-download"
                  size="small"
                  type="text"
                  v-if="scope.row.goodsStatus == '5'"
                  @click="goodsOutOfStock(scope.row.id)"
                  >下架商品
                </el-button>
                <el-button
                  v-if="
                    scope.row.goodsStatus != '2' &&
                    scope.row.goodsStatus != '4' &&
                    scope.row.goodsStatus != '5'
                  "
                  type="text"
                  size="small"
                  icon="el-icon-edit"
                  @click="editGoods(scope.row)"
                  >编辑商品</el-button
                >
                <el-popconfirm
                  title="确定是否提交审核？"
                  @confirm="submitExamine(scope.row.id)"
                >
                  <el-button
                    v-if="
                      scope.row.goodsStatus != '2' &&
                      scope.row.goodsStatus != '4' &&
                      scope.row.goodsStatus != '5'
                    "
                    slot="reference"
                    type="text"
                    size="small"
                    icon="el-icon-upload2"
                    >提交审核</el-button
                  >
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <div class="floatR Mtp20">
            <el-pagination
              background
              :total="ipagination.totalCount"
              :page-size="ipagination.size"
              :current-page="ipagination.current"
              @size-change="onSizeChange"
              @current-change="onPageChange"
              layout="total, sizes, prev, pager, next"
            ></el-pagination>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </pwd-contair>
<!--     审核不通过查看详情-->
    <el-dialog title="详情" :visible.sync="dialogVisible" width="500px">
      <div>
        {{dialogMessage}}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pwdContair from "@/components/supplier/pwdContair/pwdContair.vue";

import { mapGetters } from "vuex";
import { getUserGoodsList, goodsSumbitApply, getList, sellGoodsOutOfStock } from "@/api/product";
import { dictionary } from "@/api/dict";
import { listMixin } from "@/mixins/listMixins";

export default {
  mixins: [listMixin],
  components: { pwdContair },
  data() {
    return {
      queryParam: {
        goodsTitle: "",
        goodsTypeId: [],
        goodsStatus: "",
      },
      extraParam: {
        userId: "",
      },
      url: {
        list: getUserGoodsList,
      },
      goodsStatusDict: [],
      goodsTypeList: [],
      dialogMessage: '',
      dialogVisible: false
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.getGoodsType();
    this.extraParam.userId = this.userInfo.id;
    // this.$set(
    //   this.ipagination,
    //   "current",
    //   Number(this.$route.query.current) || 1
    // );
    // this.ipagination.current = 9;
    // this.ipagination.size = Number(this.$route.query.size) || 10;
    this.loadData(Number(this.$route.query.current) || 1);
  },
  methods: {
    initDictConfig() {
      dictionary("goods_status_dict").then((res) => {
        if (res.success) {
          this.goodsStatusDict = res.data;
        }
      });
    },
    // 新增商品
    addGoods() {
      this.$router.push({
        name: "GoodsReleased",
      });
    },
    // 编辑商品
    editGoods(row) {
      this.$router.push({
        name: "GoodsReleased",
        query: {
          id: row.id,
          current: this.ipagination.current,
          size: this.ipagination.size,
        },
      });
    },
    // 提交审核
    submitExamine(id) {
      goodsSumbitApply(id).then((res) => {
        if (res.success) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.loadData(this.ipagination.current);
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    viewGoods(row) {
      this.$router.push({
        name: "GoodDetail",
        query: {
          id: row.id,
        },
      });
    },
    mattchingDict(value) {
      let result = "";
      if(value === '3'){
        return
      }
      for (let x = 0; x < this.goodsStatusDict.length; x++) {
        if (value == this.goodsStatusDict[x].dictKey) {
          result = this.goodsStatusDict[x].dictValue;
        }
      }
      return result;
    },

    // 前往认证
    goAuthentication() {
      this.$router.push({
        name: "Authentication",
      });
    },
    // 获取商品类目数据
    getGoodsType() {
      getList().then((res) => {
        let goodsTypeList = res.data;
        for (let node of goodsTypeList) {
          this.recursive(node);
        }
        this.$set(this, "goodsTypeList", goodsTypeList);
      });
    },
    recursive(node) {
      // 如果存在孩子节点，则递归访问孩子节点
      if (node.children && node.children.length > 0) {
        for (let child of node.children) {
          this.recursive(child);
        }
      } else {
        delete node.children;
      }
    },

    /**
     * 获取数据
     */
    loadData(arg) {
      if (!this.url.list) {
        this.$message.error("请设置url.list属性!");
        return;
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg != undefined) {
        this.ipagination.current = arg;
      }
      var params = this.getQueryParams(); //查询条件
      params.goodsTypeId = params.goodsTypeId.pop();
      this.loading = true;
      this.url.list(params).then((res) => {
        let result = res;
        console.log(res.data);
        if (result.success) {
          this.$set(this, "dataSource", result.data.records);
          if (arg > 1 && this.dataSource.length === 0) {
            this.loadData(arg - 1);
          }
          this.ipagination.totalCount = result.data.total;
        } else {
          this.$message({
            type: "warning",
            message: res.msg,
          });
        }
        if (res.code === 510) {
          this.$message({
            type: "warning",
            message: res.msg,
          });
        }
        this.loading = false;
      });
    },
    // 下架商品
    goodsOutOfStock(ids) {
      if (this.validatenull(ids)) {
        this.$message({
          type: "danger",
          message: "请至少选择一条数据！",
        });
        return false;
      }
      this.$confirm("确定将选择的商品下架?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        sellGoodsOutOfStock({ goodsIds: ids }).then((res) => {
          if (res.success) {
            this.loadData(this.ipagination.current);
            this.$message({
              type: "success",
              message: res.msg,
            });
          } else {
            this.$message({
              type: "danger",
              message: res.msg,
            });
          }
        });
      });
    },

    onCLickStatus(reason){
      this.dialogVisible = true
      this.dialogMessage = reason
    }
  },
};
</script>
